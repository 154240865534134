import ajax from '@/utils/ajax.js'

// -------------------------------  网点的配送员 

/**
 * 获取配送员列表
 * @param {*} params 
 * @returns 
 */
export function getOutletsCourierList(params) {
  return ajax.post('/OutletsCourier/getList',params)
}

/**
 * 获取配送员详情
 * @param {*} params 
 * @returns 
 */
export function getOutletsCourierDetail(params) {
  return ajax.post('/OutletsCourier/detail',params)
}


/**
 * 添加配送员
 * @param {*} params 
 * @returns 
 */
export function addOutletsCourier(params) {
  return ajax.post('/OutletsCourier/add',params)
}

/**
 * 修改配送员
 * @param {*} params 
 * @returns 
 */
export function updateOutletsCourier(params) {
  return ajax.post('/OutletsCourier/update',params)
}

/**
 * 修改状态
 * @param {*} params 
 * @returns 
 */
export function updateOutletsCourierStatus(params) {
  return ajax.post('/OutletsCourier/updateStatus',params)
}

// -------------------------------  配送员 

/**
 * 获取配送员列表
 * @param {*} params 
 * @returns 
 */
export function getCourierList(params) {
  return ajax.post('/Courier/getList',params)
}


/**
 * 获取配送员详情
 * @param {*} params 
 * @returns 
 */
export function getCourierDetail(params) {
  return ajax.post('/Courier/detail',params)
}


/**
 * 添加配送员
 * @param {*} params 
 * @returns 
 */
export function addCourier(params) {
  return ajax.post('/Courier/add',params)
}

/**
 * 修改配送员
 * @param {*} params 
 * @returns 
 */
export function updateCourier(params) {
  return ajax.post('/Courier/update',params)
}

/**
 * 修改状态
 * @param {*} params 
 * @returns 
 */
export function updateCourierStatus(params) {
  return ajax.post('/Courier/updateStatus',params)
}
