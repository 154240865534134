<template>
  <div>
    <!-- 新增 编辑modal -->
    <a-modal :visible="show"
             width="500px"
             :title="type=='add'?'新增': '编辑'"
             @ok="handlerSave"  @cancel="$emit('update:show', false)">
      <a-form-model ref="addForm"
                    :labelCol="{ span: 4 }"
                    :wrapperCol="{ span: 20 }"
                    :model="formData">
        <a-form-model-item prop="name" class="mb-2" label="显示名">
          <a-input allowClear
                   v-model="formData.name"
                   placeholder="配送员名字（用户可见）"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="phone" class="mb-2" label="手机号">
          <a-input style="width:100%"
                   allowClear
                   v-model="formData.phone"
                   placeholder="手机号（用户可见）"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="realname" class="mb-2" label="真实姓名">
          <a-input allowClear
                   v-model="formData.realname"
                   placeholder="配送员真实姓名"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="identity_card" class="mb-2" label="编号">
          <a-input style="width:100%"
                   allowClear
                   v-model="formData.identity_card"
                   placeholder="配送员内部编号"></a-input>
        </a-form-model-item>
        <!-- 城市市场 -->
        <a-form-model-item v-if="scope=='all' || scope=='city'" class="mb-2" 
                    prop="outlets_city_adcode" label="城市市场">
          <a-select placeholder="选择归属的城市市场" 
                    v-model="formData.outlets_city_adcode">
            <a-select-option v-for="(item, index) in manageCityList"
                             :key="index"
                             :value="Number(item.adcode)">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 归属网点 -->
        <a-form-model-item v-if="scope=='all' || scope=='city'" class="mb-2" 
                           prop="outlets_id" label="负责的网点">
          <a-select show-search
                    placeholder="请输入负责网点"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="suggestOutletsList"
                    v-model="formData.outlets_id">
            <a-select-option v-for="(item, index) in outletsList"
                             :key="index"
                             :value="item.outlets_id">{{item.showName}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="password" class="mb-2" label="密码">
           <a-input style="width:100%"
                   allowClear
                   type="new-password"
                   v-model="formData.password"
                   placeholder="请输入密码"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getOutletsCourierDetail,
  addOutletsCourier,
  updateOutletsCourier,

  getCourierDetail,
  addCourier,
  updateCourier,
} from "@/api/system/courier.js"
import { getFinanceCityList } from "@/api/outlets"
import { getOutletsList } from '@/api/customer'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: '' // outlet、all
    },
    type: {
      type: String,
      default: 'add'
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      manageCityList: [],
      outletsList: [],
      formData: {
        id: "",
        name: "",
        realname: "",
        phone: "",
        identity_card: "",
        password: "",

        outlets_city_adcode: "",
        outlets_id: "",
      },
    }
  },
  async mounted(){
    if(this.scope == "all" || this.scope == "city"){
      await this.initFinanceCityList()
    }
    if(this.type === 'edit'){
      this.initData()
    }

    
  },
  methods: {
    async initData(){
      let detailApi = null
      if(this.scope == "all" || this.scope == "city"){
        detailApi = getCourierDetail
      }
      if(this.scope == "outlet"){
        detailApi = getOutletsCourierDetail
      }
      const {data} = await detailApi({id: this.id})
      this.formData = data
      this.formData.id = this.id
      if(data.outlets_name){
        this.suggestOutletsList(data.outlets_name)
      }
    },

    // 内部管理的城市列表
    async initFinanceCityList () {
      let list
      try{
        list = JSON.parse(localStorage.getItem('cityList'))
      }catch(e){
        console.log(e)
      }
      // 如果是城市市场账号
      if(list && list.length) {
        this.manageCityList = list
      }else{
      // 否则全部城市
        const { code, data } = await getFinanceCityList()
        if (code == 0) {
          this.manageCityList = data.list
        }
      }
    },
    async suggestOutletsList (value) {
      const params = {
        ignore_close: 2,
        outlets_city_adcode: this.formData.outlets_city_adcode,
        outlets_name: value,
      }
      if (value) {
        const { data, code } = await getOutletsList(params)
        if (code === 0) {
          // this.outletsList = data.list
          this.outletsList = data.list.map(el=>{
            let status = el.outlets_status == 2 ? "【未发布】" : (el.outlets_status == 3 ? "【营业中】" : "")
            el.showName = status + el.outlets_name
            return el
          })
        }
      }
    },

    handlerSave () {
      if (this.type == "add") {
        let addApi = null
        if(this.scope == "all" || this.scope == "city"){
          addApi = addCourier
        }
        if(this.scope == "outlet"){
          addApi = addOutletsCourier
        }
        addApi(this.formData).then((res) => {
          if (res.code == 0) {
            this.$message.success("新增成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      } else {
        let updateApi = null
        if(this.scope == "all" || this.scope == "city"){
          updateApi = updateCourier
        }
        if(this.scope == "outlet"){
          updateApi = updateOutletsCourier
        }
        updateApi(this.formData).then((res) => {
          if (res.code == 0) {
            this.$message.success("修改成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      }
    },
  }
}
</script>

<style>
</style>